// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-submit{
    padding-top: 5px;
    margin-top: 10px;
}

.form-boader{

    border-width: 4px;
    border-color: blueviolet;
    background-color: aquamarine;
    padding: 10px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Components/LoginPage/LoginPage.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;;IAEI,iBAAiB;IACjB,wBAAwB;IACxB,4BAA4B;IAC5B,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".login-submit{\n    padding-top: 5px;\n    margin-top: 10px;\n}\n\n.form-boader{\n\n    border-width: 4px;\n    border-color: blueviolet;\n    background-color: aquamarine;\n    padding: 10px;\n    border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
